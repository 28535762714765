<template>
  <div class="login">
    <div class="login__top">
      <div class="login__wrap">
        <div class="login__col login__col--left pr-6">
          <Logo
            :logo-key="logoKey"
            :enable-link="(false)"
            class="mx-0"
          />
        </div>
        <div class="login__col login__col--right pa-6">
          <v-card class="pa-10">
            <h2 class="headline mb-6">
              {{ loading
                ? $t('serviceApplication.redirectingToAuthorization')
                : $t('serviceApplication.returingToForm') }}
            </h2>
            <Spinner :loading="loading" />
          </v-card>
        </div>
      </div>
      <div class="login__wrap">
        <div class="login__col login__col--left pa-6"></div>
        <div class="login__col login__col--right pa-6 loginHelp pt-6">
        </div>
      </div>
    </div>
    <BusinessFooter />
  </div>
</template>

<script>

import {
  BusinessFooter,
  Logo,
} from '@/components'

export default {
  name: 'PalveluhakemusTunnistauduttu',
  components: {
    BusinessFooter,
    Logo,
  },
  data () {
    return {
      loading: true,
      logoKey: '',
      tunnistautuminenOnnistunut: null,
      suomiFiAuthUrl: null
    }
  },
  computed: {
    footerStyle () {
      return {
        color: this.footerTextColor,
        backgroundColor: this.footerBgColor,
      }
    }
  },
  async created () {
    this.logoKey = (process.env.VUE_APP_LOGO_LOGIN) ? process.env.VUE_APP_LOGO_LOGIN : process.env.VUE_APP_LOGO

    const accessToken = new URL(location.href).searchParams.get('access')
    const refreshToken = new URL(location.href).searchParams.get('refresh')
    if (accessToken && refreshToken) {
      this.$store.commit('palveluhakemus/setAccessToken', accessToken)
      this.$store.commit('palveluhakemus/setRefreshToken', refreshToken)

      try {
        // Huom. suomi.fi-valtuutukselle kelpaavat paluu-urlit on pyydettävä DVV:ltä domain-kohtaisesti
        const request = await this.$store.dispatch('palveluhakemus/haeSuomiFiAuthUrl')
        if (!request.success) throw new this.$HttpError(request)
        const { jwt, authorization_url } = request.result.body
        this.$store.commit('palveluhakemus/setSuomifiJwt', jwt)

        window.location.href = authorization_url
      } catch (e) {
        console.log(e)
        this.$sentryCaptureCustom(e)
        this.$naytaVirheilmoitus(this.$t('serviceApplication.authorizationErrorOccurred'))

        const timeoutMs = process.env.VUE_APP_DEV_MODE == 0 ? 3000 : 10000
        setTimeout(() => {
          this.$router.replace(
            { name: 'palveluhakemus-1',
              params: { tunnistautuminenPeruttu: true }
            },
          )
        }, timeoutMs)
      }
    } else {
      this.$router.replace(
        { name: 'palveluhakemus-1',
          params: { tunnistautuminenPeruttu: true }
        },
      )
      this.$naytaVirheilmoitus(this.$t('serviceApplication.authenticationCanceledOrErrorOccurred'))
    }
  },
}

</script>

<style lang="scss" scoped>
  @import '@/assets/scss/loginStyles.scss';
</style>
