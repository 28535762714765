<template>
  <div class="container main-div">
    <v-stepper v-model="currentStep" non-linear class="grey lighten-4">
      <v-stepper-header>
        <v-stepper-step
          :editable="currentStep > 1"
          :complete="currentStep > 1"
          step="1"
        >
          {{ stepOtsikot[1] }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :editable="currentStep > 2"
          :complete="currentStep > 2"
          step="2"
        >
          {{ stepOtsikot[2] }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :editable="currentStep > 3"
          :complete="currentStep > 3"
          step="3"
        >
          {{ stepOtsikot[3] }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="currentStep > 4">
          {{ stepOtsikot[4] }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items class="palveluhakemus__stepper-items">
        <h1 class="text-center mb-4">
          {{ stepOtsikot[currentStep] }}
        </h1>
        <v-stepper-content step="1">
          <FormPalveluhakemusPerustiedot @seuraava="currentStep = '2'" />
        </v-stepper-content>
        <v-stepper-content class="stepper" step="2">
          <template>
            <PalvelusopimusPalvelutaso @nextStep="nextStep()" />

            <!--
                <v-row>
              <v-col
                v-for="ps in valittavatPalvelusopimukset"
                :key="ps.id"
                class="px-2 mb-4 d-flex justify-center align-center"
              >
  <PalvelusopimusPalvelutaso
                  :otsikko="ps.nimi"
                  :kkhinta="ps.kkhinta"
                  :vuosihinta="ps.vuosihinta"
                  :ominaisuudet="ps.ominaisuudet"
                  @click="valitsePalvelutaso(ps)"
                />
                    </v-col>
            </v-row>
              -->
          </template>
        </v-stepper-content>
        <v-stepper-content step="3">
          <h2 class="text-center title">
            {{ $t("serviceApplication.checkCompanyInfo") }}
          </h2>
          <FormPalveluhakemusYritystiedot @seuraava="currentStep = '4'" />
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-row>
            <p class="text-center title full-width mb-4">
              {{ $t("serviceApplication.youHaveSelectedLevel") }}
            </p>
          </v-row>
          <v-row>
            <p class="text-center title full-width my-4">
              <span
                class="palveluhakemus__valittu-palvelutaso text-uppercase elevation-1"
                :class="palvelusopimus.ominaisuudet.otsikon_vari"
                >{{ palvelusopimus.nimi }}</span
              >
            </p>
            <p
              v-if="palvelusopimus.laskutusjakso === laskutusjaksoKuukausi"
              class="text-center title full-width my-4"
            >
              <span class="text-h4">{{
                palvelusopimus.kkhinta | formatSum
              }}</span>
              <span class="text-h5">/ {{ $t("monthLowerCase") }}</span>
            </p>
            <p v-else class="text-center title full-width my-4">
              <span class="text-h4">{{
                palvelusopimus.vuosihinta | formatSum
              }}</span>
              <span class="text-h5">/ {{ $t("yearLowerCase") }}</span>
            </p>
          </v-row>
          <v-row>
            <v-col
              class="mx-8 px-12 py-4 d-flex flex-column justify-center align-center"
            >
              <img
                src="@/assets/images/palveluhakemus_tunnistaudu.svg"
                class="palveluhakemus__image"
              />
            </v-col>
          </v-row>
          <v-row>
            <p class="text-center title my-4">
              {{ $t("serviceApplication.authAndReady") }}
            </p>
          </v-row>
          <v-btn
            block
            large
            class="mt-4"
            color="success"
            @click="siirryTunnistautumaanVahvasti"
          >
            {{ $t("authenticate") }}
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PaamiesPalveluhakemus } from "@/class/Paamies";
import {
  FormPalveluhakemusBase,
  FormPalveluhakemusPerustiedot,
  FormPalveluhakemusYritystiedot,
  PalvelusopimusPalvelutaso,
} from "@/components";

export default {
  name: "Palveluhakemus",
  components: {
    FormPalveluhakemusBase,
    FormPalveluhakemusPerustiedot,
    FormPalveluhakemusYritystiedot,
    PalvelusopimusPalvelutaso,
  },
  data() {
    return {
      confirmExit: true,
      currentStep: "1",
      stepOtsikot: {
        1: this.$t("serviceApplication.finishAccountSettings"),
        2: this.$t("serviceApplication.selectServiceLevel"),
        3: this.$t("serviceApplication.addPaymentInfo"),
        4: this.$t("authenticate"),
      },
      // Siirrä enviin, jos tehdään asiakaskohtaiseksi (esim. JSON-stringinä ja enviin asiakaskohtaiset käännösavaimet)
      myyntiargumentit: [
        {
          ikoni: "",
          teksti: this.$t("serviceApplication.salesArgument1"),
        },
        {
          ikoni: "",
          teksti: this.$t("serviceApplication.salesArgument2"),
        },
        {
          ikoni: "",
          teksti: this.$t("serviceApplication.salesArgument3"),
        },
      ],
      laskutusjaksoKuukausi: PaamiesPalveluhakemus.LASKUTUSJAKSO_KUUKAUSI,
      laskutusjaksoVuosi: PaamiesPalveluhakemus.LASKUTUSJAKSO_VUOSI,
    };
  },
  computed: {
    ...mapState({
      palvelusopimus: (state) => state.palveluhakemus.paamies.palvelusopimus,
      kayttaja: (state) => state.palveluhakemus.kayttaja,
      valittavatPalvelusopimukset: (state) =>
        state.palveluhakemus.valittavatPalvelusopimukset,
    }),
  },
  created() {
    if (this.$route.params.tunnistautuminenPeruttu) {
      // Palataan viimeiselle sivulle, eli jatketaan mihin jäätiin
      this.currentStep = "4";
    } else {
      //Kirjataan käyttäjä ulos
      this.kirjaaKayttajaUlos();
      // Normaali sivulle ohjaus
      // Resetoidaan lomakedata, jotta localStoragessa ei ole mahdollista vanhaa dataa.
      this.$store.commit("palveluhakemus/resetData");
      // Haetaan esitiedot
      this.$store.dispatch("palveluhakemus/haeVerkkolaskuoperaattorit");
      this.$store.dispatch("palveluhakemus/haeEsitiedot");
      this.$store.commit("palveluhakemus/updateKayttaja", {
        email: this.$route.params.email,
      });
    }
  },
  methods: {
    KirjaaKayttajaUlos() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      this.$store.dispatch("user/clearDetails");
    },
    valitsePalvelutaso(sopimus) {
      this.$store.commit("palveluhakemus/setPaamiesPalvelusopimus", sopimus);
      setTimeout(() => {
        this.currentStep++;
      }, 200);
    },
    nextStep() {
      this.currentStep++;
    },

    siirryTunnistautumaanVahvasti() {
      this.confirmExit = false;
      const url =
        process.env.VUE_APP_INSE_ANITTA_LOGIN_URL +
        "?next=/palveluhakemus/redirect/";
      /** Käytä lokaalisti kehittäessä urlia, jonka redirectissä localhost + portti, jossa tämä projekti on ajossa: */
      // const url = process.env.VUE_APP_INSE_ANITTA_LOGIN_URL + '?next=/palveluhakemus/redirect/localhost/8080/'
      window.location = url;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.confirmExit) {
      this.$swal({
        text: "Oletko varma, että haluat poistua ja hylätä syöttämäsi tiedot?",
        showCancelButton: true,
        focusCancel: true,
        cancelButtonText: "Palaa",
        confirmButtonText: "Poistu tallentamatta",
      }).then((result) => {
        if (!result.value) return;
        next();
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.main-div {
  min-height: calc(96vh - 64px);
}

.palveluhakemus {
  &__stepper-items {
    min-height: calc(96vh - 128px);
    margin: 24px;

    &--levea {
      margin: 24px 224px;
    }
  }

  &__valittu-palvelutaso {
    padding: 12px;
    border-radius: 4px;
  }

  &__image {
    height: 60vh;
  }
}
</style>
