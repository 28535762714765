var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container main-div" },
    [
      _c(
        "v-stepper",
        {
          staticClass: "grey lighten-4",
          attrs: { "non-linear": "" },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: _vm.currentStep > 1,
                    complete: _vm.currentStep > 1,
                    step: "1",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.stepOtsikot[1]) + " ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: _vm.currentStep > 2,
                    complete: _vm.currentStep > 2,
                    step: "2",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.stepOtsikot[2]) + " ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: _vm.currentStep > 3,
                    complete: _vm.currentStep > 3,
                    step: "3",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.stepOtsikot[3]) + " ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { step: "4", complete: _vm.currentStep > 4 } },
                [_vm._v(" " + _vm._s(_vm.stepOtsikot[4]) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-stepper-items",
            { staticClass: "palveluhakemus__stepper-items" },
            [
              _c("h1", { staticClass: "text-center mb-4" }, [
                _vm._v(" " + _vm._s(_vm.stepOtsikot[_vm.currentStep]) + " "),
              ]),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c("FormPalveluhakemusPerustiedot", {
                    on: {
                      seuraava: function ($event) {
                        _vm.currentStep = "2"
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "stepper", attrs: { step: "2" } },
                [
                  [
                    _c("PalvelusopimusPalvelutaso", {
                      on: {
                        nextStep: function ($event) {
                          return _vm.nextStep()
                        },
                      },
                    }),
                  ],
                ],
                2
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c("h2", { staticClass: "text-center title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("serviceApplication.checkCompanyInfo")) +
                        " "
                    ),
                  ]),
                  _c("FormPalveluhakemusYritystiedot", {
                    on: {
                      seuraava: function ($event) {
                        _vm.currentStep = "4"
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c("v-row", [
                    _c(
                      "p",
                      { staticClass: "text-center title full-width mb-4" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("serviceApplication.youHaveSelectedLevel")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("v-row", [
                    _c(
                      "p",
                      { staticClass: "text-center title full-width my-4" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "palveluhakemus__valittu-palvelutaso text-uppercase elevation-1",
                            class: _vm.palvelusopimus.ominaisuudet.otsikon_vari,
                          },
                          [_vm._v(_vm._s(_vm.palvelusopimus.nimi))]
                        ),
                      ]
                    ),
                    _vm.palvelusopimus.laskutusjakso ===
                    _vm.laskutusjaksoKuukausi
                      ? _c(
                          "p",
                          { staticClass: "text-center title full-width my-4" },
                          [
                            _c("span", { staticClass: "text-h4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatSum")(
                                    _vm.palvelusopimus.kkhinta
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "text-h5" }, [
                              _vm._v("/ " + _vm._s(_vm.$t("monthLowerCase"))),
                            ]),
                          ]
                        )
                      : _c(
                          "p",
                          { staticClass: "text-center title full-width my-4" },
                          [
                            _c("span", { staticClass: "text-h4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatSum")(
                                    _vm.palvelusopimus.vuosihinta
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "text-h5" }, [
                              _vm._v("/ " + _vm._s(_vm.$t("yearLowerCase"))),
                            ]),
                          ]
                        ),
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "mx-8 px-12 py-4 d-flex flex-column justify-center align-center",
                        },
                        [
                          _c("img", {
                            staticClass: "palveluhakemus__image",
                            attrs: {
                              src: require("@/assets/images/palveluhakemus_tunnistaudu.svg"),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [
                    _c("p", { staticClass: "text-center title my-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("serviceApplication.authAndReady")) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      attrs: { block: "", large: "", color: "success" },
                      on: { click: _vm.siirryTunnistautumaanVahvasti },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("authenticate")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }