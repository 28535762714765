<template>
  <div class="login">
    <div class="login__top">
      <div class="login__wrap">
        <div class="login__col login__col--left pr-6">
          <Logo
            :logo-key="logoKey"
            :enable-link="(false)"
            class="mx-0"
          />
        </div>
        <div class="login__col login__col--right pa-6">
          <v-card class="pa-10">
            <h2 class="headline mb-6">
              {{ $t('serviceApplication.finishingAccount') }}
            </h2>
            <Spinner :loading="loading" />
          </v-card>
        </div>
      </div>
      <div class="login__wrap">
        <div class="login__col login__col--left pa-6"></div>
        <div class="login__col login__col--right pa-6 loginHelp pt-6">
        </div>
      </div>
    </div>
    <BusinessFooter />
  </div>
</template>

<script>

import {
  BusinessFooter,
  Logo,
} from '@/components'

export default {
  name: 'PalveluhakemusTunnistauduttu',
  components: {
    BusinessFooter,
    Logo,
  },
  data () {
    return {
      loading: true,
      logoKey: '',
    }
  },
  computed: {
    footerStyle () {
      return {
        color: this.footerTextColor,
        backgroundColor: this.footerBgColor,
      }
    }
  },
  async created () {
    this.logoKey = (process.env.VUE_APP_LOGO_LOGIN) ? process.env.VUE_APP_LOGO_LOGIN : process.env.VUE_APP_LOGO

    // Käsittele error / peruutus
    const error = new URL(location.href).searchParams.get('error')
    const code = new URL(location.href).searchParams.get('code')

    if (error || !code) {
      this.$router.replace(
        { name: 'palveluhakemus-1',
          params: { tunnistautuminenPeruttu: true }
        },
      )
      this.$naytaVirheilmoitus(this.$t('serviceApplication.authorizationCanceledOrErrorOccurred'))
      return
    }

    try {
      // Näytä virheilmoitus jos y-tunnus on käytössä
      const tunnusOk = await this.tarkistaPaamies()
      if (tunnusOk !== true) {
        this.$naytaVirheilmoitus(this.$t('serviceApplication.companyIdInUse'))
        this.loading = false
        return
      }

      await this.luoTili()

      if (this.$store.state.palveluhakemus.kayttaja.markkinointiViestitHyvaksytty) {
        await this.lisaaEmailPostituslistalle()
      }

      this.$store.commit('palveluhakemus/resetData')
      this.$router.replace({ name: 'kirjaudu' })
      this.$naytaOnnistumisilmoitus(this.$t('serviceApplication.accountCreated'))
    } catch (e) {
      this.$sentryCaptureCustom(e)
      this.$naytaVirheilmoitus(this.$t('serviceApplication.accountCreationFailed'))
      this.$router.replace(
        { name: 'palveluhakemus-1',
          params: { tunnistautuminenPeruttu: true }
        },
      )
    }
  },
  methods: {
    async tarkistaPaamies () {
      const request = await this.$store.dispatch('palveluhakemus/tarkistaPaamies')
      const { body } = request.result
      if (!request.success) {
        if (request.result.status === 400 && body && body.length && body[0] === 'o') {
          return false
        } else {
          throw new this.$HttpError(request)
        }
      } else {
        return request.result.body
      }
    },
    async luoTili () {
      await this.$store.dispatch('palveluhakemus/luoTili', location.href)
    },
    async lisaaEmailPostituslistalle () {
      await this.$store.dispatch('palveluhakemus/lisaaEmailPostituslistalle')
    },

  },
}

</script>

<style lang="scss" scoped>
  @import '@/assets/scss/loginStyles.scss';
</style>
